/*
 * Copyright 2023-2024 Liberty Certified -- all rights reserved.
 */
import React from 'react'
import Container from 'react-bootstrap/Container'

const Terms = () => {
  return (
    <Container className="align-items-center">
      <Container>
        <h3 className="text-center">Terms of Use</h3>
        <p style={{ textAlign: 'justify' }}>
          <p>TODO: integrate these two bullets?</p>
          <ul>
            <li>
              {' '}
              As a consumer of products described in LC, you will respect the rights of suppliers.
            </li>
            <li>
              {' '}
              As a supplier of goods and/or services, you will tell the truth about where you
              source your components.
            </li>
          </ul>
          <h1>Liberty Certified Terms of Service</h1>
          <p>
            Thank you for choosing LC. We think you&apos;ll enjoy our platform, which is governed
            by the following Terms of Service (&quot;Agreement&quot;).
          </p>
          <p>
            Liberty Certified, P.B.C. (&quot;LC,&quot; &quot;we&quot; or &quot;us&quot;) provides
            and operates the LC services though our downloadable applications (the &quot;LC
            Apps&quot;) and the LC website (the &quot;LC Website&quot;), which may be modified from
            time to time in our sole discretion (collectively, the &quot;Services&quot;). By
            registering with us, or by using the Services, you agree to be bound by this Agreement,
            which we may change at any time by posting a notice on the LC Website and/or the LC
            Apps. Your continued use of the Services constitutes your consent to such changes.
            PLEASE READ THIS AGREEMENT CAREFULLY AND CHECK THESE TERMS OF USE PERIODICALLY FOR
            CHANGES. IF YOU DO NOT AGREE TO THESE TERMS OF USE, YOU MAY NOT USE THE SERVICES.
          </p>
          <h4>1. Conditions on Using the Services</h4>
          <p>
            a) <strong>Basic Terms.</strong> We may, in our sole discretion, refuse to offer the
            Services to any person or entity. We may, without notice and in our sole discretion,
            terminate your right to use the Services, or any portion of thereof, and block or
            prevent your future access to and use of the Services or any portion thereof.
          </p>
          <p>
            b) <strong>Your Account.</strong> A LC account is required in order to use the
            Services. You agree to provide only accurate and true information in connection with
            the creation of your account, and to update your information as necessary so it remains
            accurate and current. You may not transfer your account to any third party. You are
            responsible for keeping your account login information confidential and for any and all
            activities that occur under your account. You agree to notify us immediately if you
            suspect or become aware of any unauthorized use of your account or other breach of
            security on the Services.
          </p>
          <p>
            c) <strong>Privacy Policy.</strong> Our Privacy Policy is included in these Terms as
            Section 11 below, which describes how we may collect and use your information, may be
            viewed on and is incorporated into and considered a part of this Agreement. By using
            the Services, you agree that your information may be collected and used in accordance
            with our Privacy Policy and this Agreement.
          </p>
          <p>
            d) <strong>Minors.</strong> THE SERVICES ARE NOT INTENDED FOR CHILDREN UNDER 13, and
            persons under the age of 13 are prohibited from registering with or using the Services.
            You represent and warrant that you are more than 18 years of age or, if you are above
            13 and below 18 years of age, you are an emancipated minor, or possess legal parental
            or guardian consent, and are fully able and competent to abide by and comply with the
            terms, conditions, obligations, affirmations, representations, and warranties set forth
            in this Agreement. This provision is void where prohibited by law and the right to
            access the Sites and Services is revoked in such jurisdictions.
          </p>
          <p>
            e) <strong>Paid Accounts.</strong> If applicable, you may be accessing the Service by
            choosing and paying for one of the paid account plans available and detailed on our{' '}
            <a href="/pricing">Pricing</a> page. You may cancel your LC paid account at any time
            but you won&apos;t be issued a refund. Your paid account will remain in effect until
            it&apos;s cancelled or terminated under these Terms. If you don&apos;t pay for your
            Paid Account on time, we reserve the right to suspend it or reduce the Service levels
            accessible to you accordingly. We may change the fees in effect but will give you
            advance notice of these changes via a message to the email address associated with your
            account.
          </p>
          <h4 id="2-ownership-and-contentstrong">2. Ownership and Content</h4>
          <p>
            a) <strong>Generally.</strong> By using the Services, you will encounter &quot;LC
            Content,&quot; which includes all of the images, text, information, data, audio, video,
            graphics and other material included on or otherwise made available through the
            Services, via either the LC Apps or the LC Website, excluding User Content. &quot;User
            Content&quot; which includes all of the files, file structures, and other data that you
            submit to us, or which we must access, in order to provide the Services to you. Except
            as otherwise set forth in this Agreement we do not claim ownership over any User
            Content, and each user is solely responsible for any User Content that they provide to
            us via the Services.
          </p>
          <p>
            b) <strong>Ownership.</strong> All LC Content is owned by LC or its licensors, and is
            protected by U.S. and international copyright laws, trademark laws and/or other
            proprietary rights and laws. As between you and LC and its licensors, LC or its
            licensors own and retain, solely and exclusively, all rights, title and interest in and
            to the Services, and all LC Content that we or our licensors create and that we make
            available to you through the Services, including but not limited to any and all
            copyrights, trademark rights, trade secret rights, patent rights, database rights and
            other intellectual property and proprietary rights therein. LC and all associated logos
            and the other trademarks, service marks, logos and trade names displayed on or in
            connection with the Services are the registered and unregistered trademarks and service
            marks of LC or third parties in the United States and/or other countries. Your use of
            the Services does not grant you any ownership over any LC Content, and except for the
            limited license we grant you under this Agreement, your use of the Services does not
            grant you any license or permission under any copyright, trademark or other
            intellectual property rights of LC or any third party. We reserve all rights not
            expressly granted to you in this Agreement.
          </p>
          <p>
            c) <strong>Service and Content License.</strong> We grant you a limited, non-exclusive,
            non-sublicensable and non-transferable license to use the Services as they are provided
            to you by us, only as set forth in this Agreement and in the manner described here.
            Except as expressly permitted in this Agreement, you may not reproduce, distribute,
            adapt, modify, translate, create derivative works from, publish or otherwise use any
            portion of the Services or LC Content for any purpose without express prior written
            permission from us or the applicable rights holder. Any commercial exploitation of the
            Services or LC Content without express prior written permission from us or the
            applicable rights holder is strictly prohibited.
          </p>
          <p>
            d) <strong>Software.</strong> The LC client applications, such as LC ..., and all other
            software and software-as-a-service (SaaS) offerings used in connection with the
            Services (&quot;Software&quot;) is proprietary to us or to third parties, and except as
            may be required to exercise the foregoing license grant, any use, redistribution, sale,
            decompilation, reverse engineering, disassembly, translation or reduction of such
            software to human-readable form is prohibited. You agree that we may update the
            Software without notice, at any time and in our sole discretion, and that this
            Agreement will apply to any updated versions. If you are using LC&apos;s software
            on-premises, you may wish to reference{' '}
            <a href="">LC&apos;s standard software license agreement.</a>
          </p>
          <h4 id="3-user-content">3. User Content</h4>
          <p>
            a) <strong>Generally.</strong> You are solely responsible for ensuring that any User
            Content you submit to the Services complies with any applicable laws and third party
            rights, including but not limited to any intellectual property rights, privacy rights
            and publicity rights. You agree that any information included in your User Content may
            be used in accordance with our Privacy Policy.
          </p>
          <p>
            b) <strong>User Feedback.</strong> We appreciate your feedback and suggestions about
            our Services, but you agree that any feedback or suggestions submitted to us about the
            Services are entirely voluntary and that we will be free to use any such feedback or
            suggestions as we see fit and without any obligation to you.
          </p>
          <p>
            c) <strong>License to LC</strong> During the operation of the Services, we may access,
            scan, copy, modify, view metadata, and otherwise manipulate your User Content on a
            temporary basis solely to provide the Services as set forth under this Agreement. We do
            not claim any ownership rights in your User Content, and you remain the owner of any
            intellectual property rights that you have in your User Content. By using the Services,
            you grant LC a worldwide, non-exclusive, royalty-free, perpetual, irrevocable,
            sub-licensable (through multiple tiers) right and license to use, reproduce, adapt,
            modify, translate, and create derivative works from your User Content, solely for the
            purposes of developing, distributing, providing, and improving the Services.
          </p>
          <p>
            d) <strong>User Representations.</strong> By submitted User Content, you represent and
            warrant that (i) you own or otherwise control sufficient rights to your User Content to
            submit it, such as through Fair Use Copyright principles for research purposes, (ii)
            the use of your User Content does not violate this Agreement or the law, and will not
            violate any rights of or cause injury to any person or entity, and (iii) your User
            Content will not violate the content restrictions set forth in (FIX THIS) of this
            Agreement.
          </p>
          <p>
            e) <strong>Right to Monitor or Remove.</strong> You agree that you, and bear all risks
            associated with, your User Content. LC does not permanently store entire files on its
            servers. However, we do not permit the infringement of intellectual property rights on
            the Services, and will remove User Content from the Services if properly notified that
            such User Content infringes on another&apos;s intellectual property rights. We reserve
            the right to remove User Content from the Services, in whole or in part, without prior
            notice, for any reason or for no reason at all. Without limiting our right to terminate
            a user pursuant to <strong>Section 9</strong> of this Agreement, we reserve the right
            to terminate the account of any user of the Services who has been notified of
            infringing activity more than twice and/or has had User Content removed from the
            Service more than twice. We also reserve the right to decide whether User Content is
            appropriate and complies with this Agreement for violations other than violations of
            intellectual property law. We may remove any User Content and/or terminate a user
            account for uploading such material in violation of this Agreement at any time, without
            prior notice and at our sole discretion.
          </p>
          <p>
            f) <strong>Restrictions.</strong> You agree that you will not use the Services to:
          </p>
          <ul>
            <li>
              <p>
                i. Promote any illegal activity, or advocate, promote or assist any unlawful act;
              </p>
            </li>
            <li>
              <p>
                ii. Violate the legal rights (including the rights of publicity and privacy) of
                others or contain any material that could give rise to any civil or criminal
                liability under applicable laws or regulations or that otherwise may be in conflict
                with this Agreement and our Privacy Policy;
              </p>
            </li>
            <li>
              <p>
                iii. Transmit any pornographic, obscene, offensive, threatening, harassing,
                libelous, hate-oriented, harmful, defamatory, racist, illegal, or otherwise
                objectionable material or content;
              </p>
            </li>
            <li>
              <p>
                iv. Transmit any material or content that attempts to falsely state or otherwise
                misrepresent your identity or affiliation with a person or entity;
              </p>
            </li>
            <li>
              <p>
                v. Transmit material or content that promotes, provides, or relates to
                instructional information about illegal activities or promotes physical harm or
                injury against any individual or group;
              </p>
            </li>
            <li>
              <p>
                vi. Transmit or otherwise make available any unsolicited or unauthorized
                advertising, promotional materials, &quot;junk mail,&quot; &quot;spam,&quot;
                &quot;chain letters,&quot; &quot;pyramid schemes,&quot; or any other form of
                solicitation;
              </p>
            </li>
            <li>
              <p>
                vii. Transmit or encourage the transmission of any material that may infringe the
                intellectual property rights or other rights of third parties, including trademark,
                copyright, or right of publicity; and
              </p>
            </li>
            <li>
              <p>viii. Transmit any nude or sexually suggestive photos.</p>
            </li>
          </ul>
          <h4 id="4-other-websites-and-services">4. Other Websites and Services</h4>
          <p>
            The Services may contain links and features that enable you to access other third-party
            websites or services that are not owned or controlled by us. We do not control these
            websites or services, and we are not responsible for the contents of any linked site. A
            link does not imply endorsement of, sponsorship of, or affiliation with the linked site
            by LC Please exercise caution before proceeding to any third-party website or entering
            into any transaction with third parties linked to from the Services. Our Services
            contain features that permit you to interact with various services provided by third
            parties, including Dropbox, Google, Evernote, Trello, and Slack. By using these
            features, you agree to abide by the Terms of Services that apply to each third-party
            service. The owners of these other services do not sponsor or endorse our Services.
          </p>
          <p>
            The LC platform helps users discover three categories of content: your internal
            content, licensed content, and public content. For all types of content, LC provides
            links to the original content from its host repository. The access and use of these
            three kinds of content is permitted as follows:
          </p>
          <ul>
            <li>
              <p>
                a) use of your internal content is governed by the end user organization&apos;s
                internal data policies and controls that authorize LC&apos;s use;
              </p>
            </li>
            <li>
              <p>
                b) use of licensed content is governed by license agreements with third-party
                publishers that authorize LC&apos;s use, which may be established by LC or by your
                organization;
              </p>
            </li>
            <li>
              <p>
                c) use of publicly accessible content is permitted by the Fair Use Doctrine as
                defined in Section 107 of the U.S. Copyright Act, because LC presents short
                excerpts and links back to the original content.
              </p>
            </li>
          </ul>
          <h4 id="5-warranty-disclaimers-limitations-of-liability-indemnity">
            5. Warranty Disclaimers, Limitations of Liability, Indemnity
          </h4>
          <p>
            <strong>DISCLAIMER OF WARRANTIES.</strong> LC makes no representations or warranties of
            any kind regarding the Services or the LC Content. YOU EXPRESSLY AGREE THAT YOUR USE OF
            THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED &quot;AS IS&quot; AND
            &quot;AS AVAILABLE&quot; FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS
            OR IMPLIED, UNLESS SUCH WARRANTIES ARE LEGALLY INCAPABLE OF EXCLUSION. LC EXPRESSLY
            DISCLAIMS ANY AND ALL REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS OR IMPLIED,
            INCLUDING: (i) ALL WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            TITLE, NONINFRINGEMENT, AND ANY AND ALL WARRANTIES ARISING FROM COURSE OF DEALING AND
            USAGE OF TRADE; (ii) THAT THE SERVICES, WEBSITE AND THE LC CONTENT WILL MEET YOUR
            REQUIREMENTS, WILL ALWAYS BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE OR
            OPERATE WITHOUT ERROR; (iii) AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE OPERATION,
            USE OR OTHER EXPLOITATION OF THE SERVICES, WEBSITE OR THE LC CONTENT; AND (iv) AS TO
            THE ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED FROM THE SERVICES, WEBSITE OR
            THE LC CONTENT. No advice or information obtained by you from LC, or otherwise through
            the Services, will create any warranty not expressly stated herein.
          </p>
          <p>
            <strong>LIMITATION OF LIABILITY.</strong> LC WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY
            KIND ARISING FROM THE USE OF THE SERVICE OR FROM ANY INFORMATION, LC CONTENT,
            MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE
            AVAILABLE TO YOU THROUGH THE SERVICE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT,
            INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.
          </p>
          <p>
            CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
            LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
            DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE
            ADDITIONAL RIGHTS.
          </p>
          <p>
            <strong>Indemnity.</strong> You agree to indemnify and hold LC and its affiliates,
            subsidiaries, owners, directors, officers, employees and agents harmless from and
            against any and all claims, demands, suits, proceedings, liabilities, judgments,
            losses, damages, expenses and costs (including without limitation reasonable
            attorneys&rsquo; fees) assessed or incurred by LC, directly or indirectly, with respect
            to or arising out of: (i) your failure to comply with this Agreement; (ii) your breach
            of your obligations under this Agreement; (iii) your use of the rights granted
            hereunder, including without limitation any claims made by any third parties; (iv) your
            violation of any third party right, including without limitation any intellectual
            property, right of publicity, or privacy right; and/or (v) any claim that any of your
            User Content caused damage to a third party.
          </p>
          <p>
            <strong>6. Copyright Claims</strong>
          </p>
          <p>
            LC complies with the provisions of the Digital Millennium Copyright Act (DMCA)
            applicable to internet service providers (17 U.S.C. § 512, as amended), and responds to
            clear notices of alleged copyright infringement.
          </p>
          <p>
            In the event you believe that any Content on the Services infringes your copyright,
            then please file a notice of infringement with our designated agent:
          </p>
          <ul>
            <li>Liberty Certified, P.B.C.</li>
            <li>P.O. Box what?</li>
            <li></li>
            <li>
              <a href="dmca@libertycertified.com">dmca@libertycertified.com</a>
            </li>
          </ul>
          <p>
            Electronic notification is preferred. To be effective, any notice submitted to our
            Copyright Agent must comply with the requirements set forth at 17 U.S.C. § 512(c)(3).
            That means that the notice must include substantially the following:
          </p>
          <ol>
            <li>
              <p>
                A physical or electronic signature of a person authorized to act on behalf of the
                owner of an exclusive right that is allegedly infringed;
              </p>
            </li>
            <li>
              <p>
                Identification of the copyrighted work claimed to have been infringed, or, if
                multiple copyrighted works at a single online site are covered by a single
                notification, a representative list of such works at that site;
              </p>
            </li>
            <li>
              <p>
                Identification of the material that is claimed to be infringing or to be the
                subject of infringing activity and that is to be removed or access to which is to
                be disabled, and information reasonably sufficient to permit us to locate the
                material;
              </p>
            </li>
            <li>
              <p>
                Identification of the material that is claimed to be infringing or to be the
                subject of infringing activity and that is to be removed or access to which is to
                be disabled, and information reasonably sufficient to permit us to locate the
                material;
              </p>
            </li>
            <li>
              <p>
                A statement that you have a good faith belief that use of the material in the
                manner complained of is not authorized by the copyright owner, its agent, or the
                law; and
              </p>
            </li>
          </ol>
          <ul>
            <li>
              <ol start="6">
                <li>
                  A statement that the information in the notification is accurate, and under
                  penalty of perjury, that you are authorized to act on behalf of the owner of an
                  exclusive right that is allegedly infringed.
                </li>
              </ol>
            </li>
          </ul>
          <p>
            If we take measures to remove or disable content, we will make a good-faith attempt to
            contact, if applicable, the user who uploaded the content so that he or she may make a
            counter-notification pursuant to sections 17 U.S.C. § 512(g). Your complaint, along
            with your personally identifying information, may be shared with the user who uploaded
            the content at issue. It is our policy to document all notifications of alleged
            infringement on which we act. As with all legal notices, a copy of the notification may
            be sent to one or more third parties who may make it available to the public. Please be
            advised that it is Company&apos;s policy to terminate the accounts of users that
            repeatedly violate these Terms of Use and/or the DMCA Policy.
          </p>
          <p>
            If you are a user of the Services and User Content that you have stored on the Services
            has been removed or disabled, you may file a counter-notification pursuant to 17 U.S.C.
            § 512(g). To be effective, the counter-notification must be a written communication
            sent to the Copyright Agent listed above that includes the following:
          </p>
          <ul>
            <li>
              <ol>
                <li>Your physical or electronic signature;</li>
              </ol>
            </li>
            <li>
              <ol start="2">
                <li>
                  Identification of the material that has been removed or to which access has been
                  disabled, and the location at which the material appeared before it was removed
                  or access to it was disabled;
                </li>
              </ol>
            </li>
            <li>
              <ol start="3">
                <li>
                  A statement under penalty of perjury that you have a good faith belief that the
                  material was removed or disabled as a result of mistake or misidentification of
                  the material to be removed or disabled; and
                </li>
              </ol>
            </li>
            <li>
              <ol start="4">
                <li>
                  Your name, address, and telephone number, and a statement that you consent to the
                  jurisdiction of Federal District Court for the judicial district in which the
                  address is located or, if your address is outside of the United States, for any
                  judicial district in which Company may be found, and that you will accept service
                  of process from the person who provided notification under subsection 17 U.S.C. §
                  512(c)(1)(C) or an agent of such person.
                </li>
              </ol>
            </li>
          </ul>
          <h4 id="7-applicable-law-and-jurisdiction">7. Applicable Law and Jurisdiction</h4>
          <p>
            The Services are operated by LC from the United States, and we do not represent or
            warrant that use of the Services or any component thereof is lawful in other
            jurisdictions. Access to the Service from jurisdictions where such access is illegal is
            prohibited. All matters arising from or relating to this Agreement and the use and
            operation of the Services shall be governed by the substantive laws of the Commonwealth
            of Massachusetts, without regard to its conflicts of laws principles. Without
            derogation of the obligation to arbitrate set forth in FIX THIS Section 8 FIX THIS
            below, you agree to submit to the personal jurisdiction of the state and federal courts
            located in Boston, Massachusetts. If you gain access to the Services from locations
            outside the United States, you will be responsible for compliance with all local laws
            of any such other location, and in no event will you use the Services or Content in
            violation of U.S. export laws or regulations.
          </p>
          <h4 id="8-dispute-resolution">8. Dispute Resolution</h4>
          <p>
            All disputes arising out of or relating to this Agreement or the Services that are not
            resolved by mutual agreement shall be resolved exclusively by binding arbitration
            before a single arbitrator (the &quot;Arbitrator&quot;) in accordance with the
            Commercial Arbitration Rules of the American Arbitration Association (the
            &quot;AAA&quot;) then in effect (for information on the AAA and its rules, see{' '}
            <a href="https://web.archive.org/web/20200221214359/http://www.adr.org./">
              www.adr.org.
            </a>
            ) and the further procedures set forth herein, except that each party retains the right
            to seek injunctive or other equitable relief in a court of competent jurisdiction to
            prevent the actual or threatened infringement, misappropriation or violation of a
            party&apos;s copyrights, trademarks, trade secrets, patents or other intellectual
            property rights. The arbitration shall be conducted in Boston, Massachusetts, unless
            the Arbitrator shall determine that that venue is not reasonably convenient to all
            parties, in which case the Arbitrator shall determine another venue that is. In the
            event that the AAA is unavailable or unwilling to administer the arbitration, and the
            parties are unable to agree to a substitute, a substitute shall be appointed by the
            court. The Arbitrator shall have authority to issue any and all remedies authorized by
            law. The arbitration shall be governed by the Federal Arbitration Act, 9 U.S.C. §§ 2 et
            seq. Notwithstanding any rules of the AAA to the contrary, any claims shall be
            adjudicated on an individual basis only, and YOU WAIVE ANY RIGHT TO BRING ANY CLAIM AS
            A REPRESENTATIVE OF A PROPOSED CLASS, ON AN AGGREGATED OR MASS BASIS, OR AS A PRIVATE
            ATTORNEY GENERAL, OR TO CONSOLIDATE ARBITRATION PROCEEDINGS WITHOUT THE CONSENT OF ALL
            PARTIES THERETO. Any award rendered by the Arbitrator shall be final and binding upon
            the parties hereto. In connection with any arbitration proceeding pursuant to this
            Agreement, unless the Arbitrator shall determine otherwise, each party shall bear its
            own costs and expenses. Notwithstanding the foregoing, you may at your option file an
            individual claim in any small claims court for disputes or claims within the scope of
            its subject matter jurisdiction if such court has personal jurisdiction. The provisions
            of this arbitration section will be enforceable in any court of competent jurisdiction.
          </p>
          <h4 id="9-termination-of-service">9. Termination of Service</h4>
          <p>
            You may discontinue your use of the Services at any time. We may terminate your user
            account or right to access all or portions of the Services at any time, without notice,
            for any reason or for no reason or for conduct that we believe violates this Agreement
            and/or is harmful to other users, to LC, to other service or information providers, or
            to any third parties. After any termination of this Agreement and/or after you
            otherwise stop using the Services, the following will survive and remain enforceable
            and in full force and effect:{' '}
            <strong>
              (i) all outstanding obligations between you and us; (ii) all remedies for breach of
              this Agreement; and (iii) the following sections of this Agreement: 2(a), 2(b), and
              2(d) (Ownership and Content); 3 (User Content); 4 (Other Websites and Services); 5
              (Warranty Disclaimers, Limitations of Liability, Indemnity); 7 (Applicable Law and
              Jurisdiction); 8 (Dispute Resolution); 9 (Termination of Service); and 10
              (Miscellaneous).
            </strong>
          </p>
          <h4>10. Miscellaneous</h4>
          <p>
            No delay or omission by LC in exercising any of its rights under this Agreement will
            impair any such right or be construed to be a waiver thereof, and a waiver by LC of any
            of the covenants, conditions or agreements to be performed by you will not be construed
            to be a waiver of any succeeding breach thereof or of any other covenant, condition or
            agreement hereof contained. If any provision of this Agreement is found by a court of
            competent jurisdiction to be invalid or unenforceable, then this Agreement will remain
            in full force and effect and will be reformed to be valid and enforceable while
            reflecting the intent of the parties to the greatest extent permitted by law. Except as
            otherwise expressly provided herein, this Agreement sets forth the entire agreement
            between you and LC regarding its subject matter, and supersedes all prior promises,
            agreements or representations, whether written or oral, regarding such subject matter.
            You shall not assign or transfer this Agreement or any right or obligation hereunder to
            any third party. You agree that the electronic text of this Agreement constitutes a
            writing and your assent to the terms and conditions hereof constitutes a
            &quot;signing&quot; for all purposes.
          </p>
          <p>
            You consent to receive communications from us electronically, and you agree that we may
            communicate with you by email or by posting notices on the Services. You agree that all
            agreements, notices, disclosures and other communications that we provide to you, or
            that you provide to us, electronically satisfy any legal requirement that such
            communications be in writing. We are not responsible for any automatic filtering that
            may be applied to any email notices that we send to the email address you have
            provided.
          </p>
          <h4>11. Privacy Policy</h4>
          <p>
            This privacy policy (&quot;Policy&quot;) is designed to inform users of the LC service,
            including all associated apps, software, and websites (collectively, the
            &quot;Service&quot;) about how LC (&quot;LC,&quot; &quot;we&quot; or &quot;us&quot;)
            gathers and uses personal information collected by us in connection with the Service.
            We will take reasonable steps to protect user privacy consistent with the guidelines
            set forth in this policy and with applicable U.S. laws. In this policy,
            &quot;user&quot; or &quot;you&quot; means any person using or otherwise benefiting from
            the Service or otherwise submitting personal information to us via the Service. BY
            ACCESSING OR USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND
            AGREE TO BE BOUND BY THIS PRIVACY POLICY. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT
            USE THE SERVICE. This Privacy Policy is incorporated by reference into the{' '}
            <a href="/terms">LC Terms of Service</a>, to which you agree to be bound by using the
            Service.
          </p>
          <h4 id="what-information-do-we-collect">What Information Do We Collect?</h4>
          <p>
            Limited Personal Information: We may collect the following personal information in
            connection with the Service: (a) if you register as a LC user, we may collect personal
            information that you submit to us, such as your name and email address; (b) if
            applicable, we may collect payment information from such as your name and credit card
            information; and (c) if you communicate with us by email, we will collect your email
            address. All of this information is referred to in this Policy as &quot;Personal
            Information.&quot;
          </p>
          <p>
            Other Personal Identifiers: Apart from the limited personal information described
            above, we do not collect and do not wish to receive any personally identifying
            information. Without limitation, we request that you not submit to us any social
            security number, financial account number, credit or debit card number (other than
            payment information provided for the Service), driver&apos;s license number or
            government ID number.
          </p>
          <p>
            User Data: When using the Service, and depending on the nature of user files and their
            location, user files and names and portions thereof are uploaded to the Service. All
            data uploaded by users at the direction of users is referred to in this Policy as
            &quot;User Data.&quot;
          </p>
          <p>
            Web Tracking Information: We, and our third party service providers, may use web
            tracking technologies such as cookies, web beacons, pixel tags and clear GIFs in order
            to operate the Service efficiently and to collect data related to usage of the Service.
            Such collected data (&quot;Web Tracking Information&quot;) may include the address of
            the websites you visited before and after you visited the Service, the type of browser
            you are using, your Internet Protocol (IP) address, what pages in the Service you visit
            and what links you clicked on, and whether you opened email communications we send to
            you. In order to collect Web Tracking Information and to make your use of the Service
            more efficient, we may store cookies on your computer. We may also use web tracking
            technologies that are placed in web pages on the Service or in email communications to
            collect information about actions that users take when they interact with the Service
            or such email communications, and our Contractors (as defined herein) may also do so.
            We do not correlate Web Tracking Information to individual user Personal Information,
            except for internal operational, product development, and administrative purposes of
            the Service. Some Web Tracking Information may include data, such as IP address data,
            that is unique to you. You may be able to modify your browser settings to alter which
            web tracking technologies are permitted when you use the Service, but this may affect
            the performance of the Service.
          </p>
          <h4 id="how-do-we-use-the-information-we-collect">
            How Do We Use the Information We Collect?
          </h4>
          <p>
            Personal Information: Your Personal Information may be supplemented with additional
            information regarding your activities on the Service; to the extent that such
            information is linked specifically to you, we will treat that additional information as
            your Personal Information. We may use your email address to send you informational
            materials regarding the Services, or marketing materials regarding LC&apos;s other
            products and services. We may also use Personal Information for the internal
            operational, product development, and administrative purposes of the Service.
          </p>
          <p>
            User Data: We collect and temporarily store User Data in order to provide the Service
            to you, and it may be used for the internal operational, product development, and
            administrative purposes of the Service.
          </p>
          <p>
            Web Tracking Information: We use Web Tracking Information to administer the Service and
            to understand how well our Service is working, to store your user preferences, and to
            develop statistical information on usage of the Service. This helps us improve our
            Service, personalize your user experience, and to measure overall effectiveness.
          </p>
          <p>
            Aggregate Information: We will also create statistical, aggregated data relating to our
            users and the Service for analytical purposes (&quot;Aggregate Information&quot;).
            Aggregate Information is derived from Personal Information and User Data but in its
            aggregated form it does not duplicate or reveal any User Data or relate to or identify
            any individual. This data is used to understand our customer base and to develop,
            improve and market the Service and our other services.
          </p>
          <h4 id="what-information-do-we-disclose-to-third-parties">
            What Information Do We Disclose to Third Parties?
          </h4>
          <p>Personal Information and User Data</p>
          <p>
            We will not disclose your Personal Information or User Data to any third parties except
            as follows:
          </p>
          <p>
            (i) to third party contractors engaged to provide services on our behalf
            (&quot;Contractors&quot;), such as performing marketing, analyzing data and usage of
            the Service, hosting and operating the Service or providing support and maintenance
            services for the Service, or providing customer service. We enter into agreements with
            all Contractors that require Contractors to use the Personal Information they receive
            only to perform services for us.
          </p>
          <p>(ii) when we have your consent to share the information.</p>
          <p>
            Web Tracking Information: We disclose Web Tracking Information to Contractors, in order
            to analyze the performance of the Service and the behavior of users, and to operate and
            improve the Service.
          </p>
          <p>
            Aggregate Information: We may disclose aggregated data that does not contain Personal
            Information or User Data to any third parties, such as media outlets, potential
            customers, business partners, and funding sources, in order to describe our business
            and operations.
          </p>
          <p>
            Network Operators: Use of the Service may involve use of the services of third party
            telecommunications carriers. These carriers are not our contractors, and any
            information that a carrier collects in connection with your use of the Service is not
            &quot;Personal Information&quot; and is not subject to this Privacy Policy. We are not
            responsible for the acts or omissions of telecommunications carriers.
          </p>
          <p>
            Legal Exception: Notwithstanding anything to the contrary in this Policy, we may
            preserve or disclose your information if we believe that it is reasonably necessary to
            comply with a law, regulation or legal request; to protect the safety of any person; to
            address fraud, security or technical issues; or to protect our rights or property.
            However, nothing in this Policy is intended to limit any legal defenses or objections
            that you may have to a third party&apos;s, including a government&apos;s, request to
            disclose your information.
          </p>
          <p>
            Business Transfers: In the event that LC is involved in a bankruptcy, merger,
            acquisition, reorganization or sale of assets, your information may be sold or
            transferred as part of that transaction. This Policy will apply to your information as
            transferred to the new entity.
          </p>
          <h4 id="privacy-settingsopt-out">Privacy Settings/Opt Out</h4>
          <p>
            If you wish to modify any of your user profile information, remove your email from our
            mailing list, or cancel your account, please contact us at{' '}
            <a href="mailto:support@libertycertified.com">support@libertycertified.com</a>.
          </p>
          <p>
            Your removal from our mailing list or database will not remove User Data you have
            submitted to us or records of past use of the Service, nor delete information stored in
            our data backups and archives. User Data, including User Data submitted, data on past
            queries and data stored in backups and archives will be maintained and/or deleted in
            the ordinary course of our business. LC does not provide copies of User Data to its
            customers.
          </p>
          <h4>General</h4>
          <p>
            Security: We use reasonable security precautions to protect the security and integrity
            of your Personal Information in accordance with this policy and applicable law.
            However, no Internet transmission is completely secure, and we cannot guarantee that
            security breaches will not occur. Without limitation of the foregoing, we are not
            responsible for the actions of hackers and other unauthorized third parties that breach
            our reasonable security procedures.
          </p>
          <p>
            Links: The Service may contain links to other websites and services, including payment
            services. We are not responsible for the privacy practices or the content of those
            websites, and you should review the privacy statements of each third party website you
            visit. This Policy applies solely to information collected by the Service.
          </p>
          <p>
            Amendments: We may revise this Policy from time to time. The most current version of
            the Policy will govern our use of your information and will always be located at{' '}
            <a href="/terms">libertycertified.com/terms</a>. If we make a change to this Policy
            that, in our sole discretion, is material, we will notify you via the Service or via
            email to the email address associated with your account. By continuing to access or use
            the Service after those changes become effective, you agree to be bound by the revised
            Policy.
          </p>
          <p>
            Children: We do not knowingly collect or retain personally identifiable information
            about persons under 13 years of age. Any person who provides their personal information
            to us via the Service represents that they are 13 years of age or older. We encourage
            parents and legal guardians to monitor the activity of those under 13 years of age. If
            you have reason to believe that a child under the age of 13 has provided personally
            identifiable data to us, please contact us, and we will make every effort to delete
            that information from our databases.
          </p>
          <p>
            Service Visitors from outside the United States: LC and its servers are located in the
            United States and are subject to the applicable state and federal laws of the United
            States. If you choose to access or use the Service, you consent to the use and
            disclosure of information in accordance with this privacy policy and subject to such
            laws.
          </p>
          <p>
            <em>The effective date of these Terms of Service is February 1, 2024.</em>
          </p>
        </p>
      </Container>
    </Container>
  )
}

export default Terms
