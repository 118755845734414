/*
 * Copyright 2023-2024 Liberty Certified -- all rights reserved.
 */
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const MainFooter = () => {
  const { t } = useTranslation()

  return (
    <footer
      className="text-center text-white mt-auto"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
    >
      <Container className="p-2"></Container>
      <Container>
        <Row className="border-bottom mb-1 pb-1">
          <Col>
            <h4>
              <a
                href="/our_mission"
                className="link-light link-offset-2 link-opacity-75 link-opacity-100-hover link-underline-opacity-0 link-underline-opacity-0-hover"
              >
                {t('common.our-mission')}
              </a>
            </h4>
          </Col>
          <Col>
            <h4>
              <a
                href="/terms"
                className="link-light link-offset-2 link-opacity-75 link-opacity-100-hover link-underline-opacity-0 link-underline-opacity-0-hover"
              >
                Terms of Use
              </a>
            </h4>
          </Col>
        </Row>
        <Row className="py-2">
          <p className="text-center">&copy; 2023-2024 Liberty Certified, P.B.C.</p>
        </Row>
      </Container>
    </footer>
  )
}

export default MainFooter
