/*
 * Copyright 2023-2024 Liberty Certified -- all rights reserved.
 */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import './App.css'
import EditAccountForm from './components/EditAccountForm'
import EditCompanyForm from './components/EditCompanyForm'
import EditOriginReportForm from './components/EditOriginReportForm'
import EditPMForm from './components/EditPMForm'
import Footer from './components/Footer'
import Header from './components/Header'
import AccountInfo from './pages/AccountInfo'
import ActivatePage from './pages/ActivatePage'
import CompanyInfo from './pages/CompanyInfo'
import CompanyOriginReport from './pages/CompanyOriginReport'
import CompanyPM from './pages/CompanyPM'
import Home from './pages/Home'
import OriginReportCreate from './pages/OriginReportCreate'
import OriginReportInfo from './pages/OriginReportInfo'
import OriginReportList from './pages/OriginReportList'
import OurMission from './pages/OurMission'
import PMAccountInfo from './pages/PMAccountInfo'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import Terms from './pages/Terms'
import PrivateRoute from './utils/PrivateRoute'

const queryClient = new QueryClient({})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="d-flex flex-column min-vh-100">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/origin_report" element={<OriginReportList />} />
            <Route path="/origin_report/:originReportIdentifier" element={<OriginReportInfo />} />
            <Route path="/our_mission" element={<OurMission />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/activated/:uidb64/:token" element={<ActivatePage />} />
            <Route exact path="/account" element={<PrivateRoute />}>
              <Route exact path="/account/info" element={<AccountInfo />} />
              <Route exact path="/account/origin_report" element={<CompanyOriginReport />} />
              <Route exact path="/account/origin_report/create" element={<OriginReportCreate />} />
              <Route
                exact
                path="/account/origin_report/edit/:originReportIdentifier"
                element={<EditOriginReportForm />}
              />
              <Route exact path="/account/edit" element={<EditAccountForm />} />
              <Route exact path="/account/pm" element={<CompanyPM />} />
              <Route exact path="/account/pm/edit/:pmEmail" element={<EditPMForm />} />
              <Route exact path="/account/pm/info/:pmEmail" element={<PMAccountInfo />} />
              <Route exact path="/account/company/info/:companyName" element={<CompanyInfo />} />
              <Route
                exact
                path="/account/company/edit/:companyName"
                element={<EditCompanyForm />}
              />
            </Route>
          </Routes>
          <Footer />
        </div>
      </Router>
    </QueryClientProvider>
  )
}

export default App
