/*
 * Copyright 2023-2024 Liberty Certified -- all rights reserved.
 */
import React from 'react'
import { Container } from 'react-bootstrap'

import OriginReportForm from '../components/OriginReportForm'

const OriginReportCreate = () => {
  return (
    <Container className="my-4 px-4 py-2 rounded shadow">
      <OriginReportForm />
    </Container>
  )
}

export default OriginReportCreate
