/*
 * Copyright 2023-2024 Liberty Certified -- all rights reserved.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { Row } from 'react-bootstrap'

const ImageComponent = ({ src, text }) => {
  ImageComponent.propTypes = {
    src: PropTypes.string,
    text: PropTypes.string,
  }

  return (
    <>
      <Row className="text-secondary">
        <p>{text}</p>
      </Row>
      <Row className="pb-3 my-auto">
        <img
          className="img-component border rounded p-3"
          src={src}
          // Means text or src. Eslint replaced it from "text ? text : src" statement
          alt={text || src}
        />
      </Row>
    </>
  )
}

export default ImageComponent
