/*
 * Copyright 2023-2024 Liberty Certified -- all rights reserved.
 */
import { useQuery } from '@tanstack/react-query'

import { axios } from './axios'

const getCountryList = async () => {
  const response = await axios.get('/country/list/')
  const countryList = response.data
  countryList.sort((a, b) => {
    return a.name > b.name ? 1 : a.name === b.name ? 0 : -1
  })
  return countryList
}

export const useCountryListQuery = () => {
  return useQuery({
    queryKey: ['countries'],
    queryFn: () => getCountryList(),
    staleTime: 5 * 60 * 1000,
  })
}
